import {
  RequestValidationError,
  TsRestHttpError,
  TsRestRequest,
  TsRestResponse,
} from "@ts-rest/serverless";

export function errorHandler(error: unknown, req: TsRestRequest): TsRestResponse | undefined {
  // You can also handle the base error here if all of your custom errors extends a base error class
  if (error instanceof UnauthenticatedError) {
    return error.toResponse();
  } else if (error instanceof NotFoundError) {
    return error.toResponse();
  } else if (error instanceof UnprocessableEntityError) {
    return error.toResponse();
  } else if (error instanceof RequestValidationError) {
    // do not console log the error. The error is logged in responseHandlers
  } else if (error instanceof TsRestHttpError && error.statusCode === 404) {
    // do not console log the 404 error
  } else {
    console.error(error);
  }
}

class BaseError {
  status: number;
  message: string;
  constructor(props: { status: number; message: string }) {
    this.message = props.message;
    this.status = props.status;
  }

  toResponse(): TsRestResponse {
    return TsRestResponse.fromJson({ message: this.message }, { status: this.status });
  }
}

export class UnauthenticatedError extends BaseError {
  constructor() {
    super({ status: 401, message: "Unauthenticated" });
  }
}

export class NotFoundError extends BaseError {
  constructor() {
    super({ status: 404, message: "Not Found" });
  }
}

export class UnprocessableEntityError extends BaseError {
  constructor(message: string) {
    super({ status: 422, message: message });
  }
}
